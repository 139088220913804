@import "_default.scss_include-mixins";

body {
	font-family:'Cabin', serif;
	background-color:#fff;
}

/*
Selection
*/
::selection {
	background:#000;
	color:#fff;
}

/*
Marges
*/
.page-marge {
	float:left;
	width:100%;
	margin-top:120px;
	padding-bottom:40px;
}

/*
Focus
*/
.focus {
	overflow:hidden;
}

/*
Link
*/
a.bottomline {

    text-decoration:none;
    //width: calc(100%);
    background-image: linear-gradient(transparent calc(100% - 2px), white 1px);
    background-repeat: no-repeat;
    background-size: 0% 100%;
    transition: background-size 0.5s;

    @include sm {
        background-image: linear-gradient(transparent calc(100% - 2px), white 1px);
    }
    
    &:hover {
        background-size: 100% 100%;
    }

}

/*
Animation
*/
@keyframes rotate {
    0% {
    	 transform: rotate(0deg);
    }

    20% {
        transform: rotate(180deg);
    }

    40% {
    	transform: rotate(360deg);
    }

    100% {
		transform: rotate(360deg);
    }

}
