@import "_default.scss_include-mixins";	 

header {
	
	position:fixed;
	top:-100px;
	left:0;
	width:100%;
	padding:5px;
	background-color:#fff;
	box-shadow:0 0 30px rgba(0,0,0,0.4);
	z-index:200;

	.logo {
		
		float:left;
		margin-top:5px;
		cursor:pointer;

		svg {
			width:100px;
			height:35px;

			@include sm {
				width:80px;
				height:35px;
				margin-left:10px;
			}

			.st0 {
				fill:#434343;
			}
			.st1 {
				fill:#757575;
			}
			
		}
	}

	nav {	

		@include lg {
			display:none;
		}

		margin-top:5px;

		a {
			display:inline-block;
			font-family:'Cabin',arial;
			font-weight:bold;
			font-size:20px;
			color:rgba(0,0,0,0.5);
			padding:5px;
			text-decoration: none;

			&:hover,
			&.active {
				color:rgba(0,0,0,1);
			}

		}

	}

	.menu {

		display:none;

		@include lg {
			display:block;
			cursor:pointer;
		}

		position:relative;
		float:right;
		margin-right:10px;
		margin-top:10px;
		width:40px;
		height:27px;

		.line {
			position:absolute;
			top:0;
			height:3px;
			width:100%;
			background-color:#000;

			&.line-1 {
				top:0;
			}
			&.line-2 {
				top:10px;
			}
			&.line-3 {
				top:20px;
			}

		}

	}

}