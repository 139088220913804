@import "_default.scss_include-mixins";

.popup--diensten {
	
	width:900px;

	@include lg {
		width:80vw;
	}

	@include sm {
		width:80vw;
	}

	.content {
		float:left;
		width:100%;
		padding-top:0;
		padding-bottom:0;
		padding:40px;
		background-color:#fff;

		h1 {
			margin:0;
			padding:0;
			margin-bottom:10px;
		}
	}

}