@import "_default.scss_include-mixins";

.button {
	cursor:pointer;
	
	&.button-1 {
		float:left;
		text-decoration:none;

		padding:10px;
		padding-left:30px;
		padding-right:30px;

		text-align:center;
		background-color:#1A4236;
		border-radius:30px;

		&:hover > .icon svg {
			opacity:1;
		}

		.icon {
			float:left;
			margin-left:20px;
			margin-top:7px;

			&.left {
				transform:rotate(180deg);
				margin-right:20px;
				margin-left:0;
				margin-top:4px;
				margin-bottom:3px;
			}

			svg {
				width:20px;
				height:20px;
				fill:#fff;
				opacity:0.4;
			}

		}

		&.mr {
			margin-right:20px;
		}

		.label {
			float:left;
			margin-top:3px;
			font-family:'Gilroy',arial;
			font-size:22px;
			line-height:22px;
			color:#fff;
			font-weight:normal;
			text-decoration:none;
		}

		&.inline {
			float:inherit;
			width:auto;
		}
		
	}

}