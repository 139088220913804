@import "_default.scss_include-mixins";

.textblock-1 {

	float:left;
	width:100%;

	p,ul li,.p {
		padding:0;
		margin:0;
		font-size:16px;
		line-height:25px;
		color:#000;

		a {
			color:#888;
		}

		@include sm {
			font-size:14px;
			line-height:24px;
		}

	}

	ul {
		list-style-type: disc;
	}

	h1 {
		font-size:30px;
	    line-height:30px;

	    @include sm {
	    	font-size:25px;
	    	line-height:25px;
	    }
	}
		
	h2 {
	    color:#000;
	    font-family:'Cabin',sans-serif;
	    font-weight:bold;
	    font-size:20px;
	    line-height:30px;

	    @include sm {
			font-size:20px;
			line-height:20px;
		}

	}

}

.textblock-2 {

	float:left;
	width:100%;

	p,ul li,.p {
		padding:0;
		margin:0;
		font-size:20px;
		line-height:35px;
		color:#888;

		a {
			color:#000;
		}

		@include sm {
			font-size:14px;
			line-height:24px;
		}

	}

	h1 {
		margin-top:0;
	    padding-top:0;
	}

	ul {
		list-style-type: disc;
	}
		
	h2 {
	    color:#000;
	    font-family:'Cabin',sans-serif;
	    font-weight:bold;
	    font-size:20px;
	    line-height:30px;

	    @include sm {
			font-size:20px;
			line-height:20px;
		}

	}

}