@import "_default.scss_include-mixins";

footer {

	float:left;
	width:100%;
	padding:40px;
	padding-top:30px;
	padding-bottom:30px;;
	background-color:#000;
	text-align:center;

	svg {
		display:inline-block;
		width:200px;
		height:100px;

		@include sm {
			width:100px;
			height:auto;
		}

		.st0{fill:#F1EFEF;}
		.st1{fill:#999999;}

	}

	.nav {
		float:left;
		width:100%;
		margin-top:20px;

		font-size:15px;
		color:#fff;

		text-align:center;

		.inside {
			display:inline-block;
			width:40%;
		}

		span {
			opacity:0.5;
		}

		.algemenevoorwaarden {
			display:inline-block;
			font-size:15px;
			color:#fff;
			opacity:0.5;
			text-decoration:none;

			&:hover {
				opacity:1;
			}

		}

		.copyright {
			display:inline-block;
			font-size:15px;
			color:#fff;
			opacity:0.5;
			margin-top:10px;

			&.hover {
				opacity:1;
			}

		}

		.btw {
			display:inline-block;
			font-size:10px;
			color:#fff;
			opacity:0.5;
			margin-top:20px;
		}

	}

}