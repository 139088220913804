@import "_default.scss_include-mixins";

.page.content--home {

	/*
	Spotlight ***************************
	Spotlight ***************************
	Spotlight ***************************
	Spotlight ***************************
	*/
	.spotlight {
		position:relative;
		width:100%;
		height:100vh;
		height:calc(var(--vh, 1vh) * 100);
		min-height:880px;
		background-color:#D6603A;

		@include xl2 {
			min-height:610px;
		}

		@include md {
			min-height:100%;
		}

		@include sm {
			min-height:400px;
		}

		.nav {
			position:absolute;
			top:40px;
			right:40px;

			a {
				display:inline-block;
				font-family:'Cabin',arial;
				font-weight:bold;
				font-size:20px;
				color:#fff;
				padding:5px;
				text-decoration: none;
				opacity:0.5;

				&:hover,
				.active {
					opacity:1;
				}

			}

		}

		.exit {
			position:fixed;
			top:-110px;
			right:20px;
			width:40px;
			height:40px;
			padding:10px;
			background-color:#fff;
			cursor:pointer;
			border-radius:50%;

			.icon {

				position:absolute;
				top:50%;
				left:50%;
				width:25px;
				height:25px;
				
				transform:translate(-50%,-50%);

				svg {
					width:100%;
					height:100%;
				}

			}

		}

		.video {
			position:absolute;
			top:50%;
			left:50%;
			width:1140px;
			height:641px;
			background-color:#000;
			transform:translate(-50%,-55%);
			box-shadow:0 0 30px rgba(0,0,0,0.4);
			z-index:100;

			.black {
				position:absolute;
				left:0;
				top:0;
				height:100%;
				width:100%;
				background-color:#000;
				opacity:0.85;
				z-index:1;
			}

			&.playing {
				position:fixed;
				transform:translate(-50%,-50%);
				z-index:300;

				video {
					object-fit:contain;
				}
			}

			&.mobile {
				transform:none;
				left:0;
				top:0;
			}

			@include xl2 {
				width:888px;
				height:500px;
				transform:translate(-50%,-58%);
			}

			@include md {
				width:320px;
				height:180px;
				transform:translate(-50%,-62%);
			}

			@include sm {
				width:100%;
				height:80%;
				transform:none;
				left:0;
				top:0;
			}

			.float {
				position:absolute;
				left:50%;
				top:50%;
				transform:translate(-50%,-50%);
				z-index:3;
				cursor:pointer;

				@include md {
					width:180px;
				}

				@include sm {
					width:190px;
				}

				.logo {

					.icon {
						float:left;
						animation: rotate 5s infinite;

						svg {
							width:200px;
							height:200px;

							@include xl2 {
								width:150px;
								height:150px;
							}

							@include md {
								width:60px;
								height:60px;
							}

							@include sm {
								width:60px;
								height:60px;
							}

							.st0{
								fill:#999999;
							}
							.st1{
								fill:#F1EFEF;
							}
						}

						@include sm {
							margin-left:10px;
						}

					}

					.label {
						float:left;
						margin-left:20px;
						margin-top:10px;

						width:270px;

						@include xl2 {
							width:200px;
						}

						@include md {
							margin-left:10px;
							margin-top:3px;
							width:82px;
						}

						@include sm {
							margin-left:10px;
							margin-top:3px;
							width:82px;
						}

					}

				}

				.title {

					float:left;
					width:100%;
					margin-top:30px;
					text-align:center;

					@include md {
						margin-top:10px;
					}

					@include sm {
						margin-top:20px;
					}

					.center {
						display:inline-block;

						.left {
							float:left;
							font-size:30px;
							line-height:40px;
							font-weight:bold;
							color:#fff;

							@include md {
								font-size:13px;
								line-height:17px;
							}

							@include sm {
								font-size:13px;
								line-height:17px;
							}

						}

						.right {
							float:left;
							margin-left:20px;

							@include md {
								margin-left:10px;
								margin-top:-3px;
							}

							@include sm {
								margin-left:10px;
								margin-top:-3px;
							}

							svg {
								width:80px;
								height:80px;
								fill:#fff;

								@include md {
									width:35px;
									height:35px;
								}

								@include sm {
									width:40px;
									height:40px;
								}
							}
						}

					}

				}

			}

			.overlay {
				position:absolute;
				left:0;
				top:0;
				width:100%;
				height:100%;
				z-index:2;
			}

			video {
				position:absolute;
				left:0;
				top:0;
				width:100%;
				height:100%;
				opacity:0.1;
				z-index:0;
				object-fit:cover;
			}

		}

		.down {

			position:absolute;
			bottom:55px;
			left:50%;
			width:400px;
			font-size:18px;
			line-height:18px;
			margin-left:-200px;
			text-align:center;

			@include xl2 {
				font-size:18px;
				line-height:18px;
			}

			@include md {
				width:240px;
				font-size:12px;
				line-height:12px;
				bottom:50px;
				margin-left:-120px;
			}

			@include sm {
				width:240px;
				font-size:15px;
				line-height:15px;
				bottom:50px;
				margin-left:-120px;
			}

		}

		.down-icon {
			position:absolute;
			bottom:0px;
			left:50%;
			margin-left:-35px;

			width:70px;
			height:35px;
			background-color:#fff;
			border-top-left-radius: 35px;  /* 100px of height + 10px of border */
    		border-top-right-radius: 35px; /* 100px of height + 10px of border */
			cursor:pointer;
			z-index:10;
			box-shadow:0 0 30px rgba(0,0,0,0.2);

			&.zoom {
				animation: zoominout 0.2s 1;
			}

			span {

				position:absolute;
				top:50%;
				left:50%;
				width:20px;
				height:20px;
				transform:translate(-50%,-50%);
				transform:rotate(90deg);

				margin-left: -9px;
				margin-top: -8px;

				svg {
					width:20px;
					height:20px;
				}

			}

		}

	}

	/*
	Rest ***************************
	Rest ***************************
	Rest ***************************
	Rest ***************************
	*/
	.rest {
		float:left;
		position:relative;
		width:100%;
		text-align:center;

			.line {
				position:absolute;
				top:0;
				left:50%;
				width:2px; 
				height:40px;
				background-color:#D6603A;
			}

			.slideshows {

				float:left;
				position:relative;
				width:100%;
				margin-top:80px;
				overflow:hidden;

				.slideshow {

					float:left;
					position:relative;
					width:100%;
					min-height:100px;
					margin-bottom:40px;

					.swiper-container {
					  width:100%;
					  height:100%;
					}

					.info {
						position:absolute;
						top:40px;
						left:40px;
						border-radius:50%;
						z-index:10;

						@include sm {
							top:20px;
							left:20px;
						}

						&.active {

							.breakout {
								float:left;
								width:640px;
								height:auto;
								transform:scale(1);
								padding-bottom:10px;
								padding-left:10px;
								padding-right:10px;

								@include md {
									@include orientation(landscape) {
										padding-top:10px;
										width:400px;
										height:auto;
									}
								}

								@include sm {
									padding-top:10px;
									padding-bottom:20px;
									width:260px;
									height:auto;
								}

								.inside {
									float:left;
									width:100%;
									max-height:400px;
									padding:20px;
									padding-top:10px;
									overflow:auto;

									h2 {
										@include md {
											@include orientation(landscape) {
												padding-top:0;
												padding-bottom:0;
												margin:0;
												margin-bottom:10px;
											}
										}
									}

									@include md {
										@include orientation(landscape) {
											max-height:180px;
										}
									}

									@include sm {
										max-height:300px;
									}

								}
								
							}
						}

						.icon {
							position:absolute;
							top:0;
							left:0;
							z-index:20;
							cursor:pointer;

							&.zoom {
								animation: zoominout 0.2s 2;
							}

							span {
								float:left;
								width:40px;
								height:40px;
								background-color:#fff;
								border-radius:50%;
								box-shadow:0 0 10px rgba(0,0,0,0.2);
								border:2px solid #fff;
							}
						}

						.breakout {
							position:absolute;
							top:20px;
							left:20px;
							width:0;
							height:0;
							padding:0;
							border-radius:10px;
							box-shadow:0 0 30px rgba(0,0,0,0.1);
							background-color:#fff;
							transition: all 0.5s ease;	
							text-align:left;
							overflow:hidden;				
						}

					}

					.nav {
						position:absolute;
						width:70px;
						height:70px;
						background-color:#fff;
						border-radius:50%;
						cursor:pointer;
						z-index:10;
						box-shadow:0 0 30px rgba(0,0,0,0.2);
						opacity:0.5;

						span {

							position:absolute;
							top:50%;
							left:50%;
							width:20px;
							height:20px;
							transform:translate(-50%,-50%);

							svg {
								width:20px;
								height:20px;
								fill:#000;
								opacity:0.2;
							}

						}

						&.left {

							span {
								left:70%;

								svg {
									transform:rotate(180deg);
								}

								@include xl3 {
									left:50%;
								}
							}

						}

						&.right {

							span {
								left:30%;

								@include xl3 {
									left:50%;
								}

							}

						}

						&.left {
							left:-35px;
							top:50%;
							margin-top:-35px;

							&.zoom {
								animation: zoominout 0.2s 1;
							}

						}
						&.right {
							right:-35px;
							top:50%;
							margin-top:-35px;

							&.zoom {
								animation: zoominout 0.2s 1;
							}

						}

						@keyframes zoominout {
						    0% {
						        transform: scale(1,1);
						    }
						    50% {
						        transform: scale(1.2,1.2);
						    }
						    100% {
						        transform: scale(1,1);
						    }
						}


						&.active {
							background-color:#D6603A;
							opacity:1;

							span {
								svg {
									fill:#000;
									opacity:1;
								}
							}
						}

					}

					.slide {

						background-color:#fff;

						&.image {
							background-size:cover;
							background-position:center;

							@include sm {
								background-size:cover;
								background-repeat:no-repeat;
							}
						}
						&.video {

							background-image:url('../img/static/assets/loading.svg');
							background-repeat:no-repeat;
							background-position:center;
							background-size:4%;

							@include sm {
								background-size:8%;
							}

							.noclick {
								position:absolute;
								top:0;
								left:0;
								width:100%;
								height:100%;
							}

							iframe {
								width:100%;
								height:100%;
							}

							video {
								width:100%;
								height:100%;
								object-fit:cover;
							}
							
						}

					}

				}

			}

	}

	.about {
		float:left;
		width:100%;
		margin-top:40px;
		padding:80px;
		background-color:#fff;
		text-align:center;

		@include sm {
			padding:20px;
		}

		.portret {
			float:left;
			width:100%;
			height:400px;
			background-image:url('../img/temp/portret.jpg');
			background-position:center;
			background-size:contain;
			background-repeat:no-repeat;

			@include md {
			 margin-bottom:40px;
			 background-size:cover;
			}

			@include sm {
			 margin-bottom:0px;
			 padding-top:100%;
			 height:auto;
			  margin-bottom:40px;
			 background-size:contain;
			}

		}
	}

	.contact {
		float:left;
		width:100%;
		margin-top:40px;
		padding:80px;
		padding-top:80px;
		padding-bottom:80px;
		color:#fff;
		text-align:center;
		background-color:#D6603A;

		@include sm {
			padding:20px;
		}

		.textblock-1 {

			display:inline-block;
			float:none;
			width:60%;

			p {
				font-size:27px;
				line-height:35px;
				color:#fff;

				@include md {
					font-size:16px;
					line-height:25px;
				}

				@include sm {
					font-size:16px;
					line-height:25px;
				}

			}
		}

		.big {
			display:inline-block;
			font-size:40px;
			line-height:40px;
			margin-top:30px;
			color:#fff;
			text-align:center;
			text-decoration:none;
			font-weight:bold;

			@include md {
				font-size:20px;
				line-height:35px;
				margin-top:10px;
			}

			@include sm {
				font-size:20px;
				line-height:35px;
				margin-top:5px;
			}

			span {
				font-family:arial;
			}
		}

		.socials {
			float:left;
			width:100%;
			text-align:center;
			margin-top:40px;

			@include sm {
				margin-top:20px;
			}

			a {
				display:inline-block;
				transition: all 0.2s;

				svg {
					width:4.5vw;
					height:4.5vw;
					fill:#fff;
					margin-left:20px;
					margin-right:20px;

					@include sm {
						margin:5px;
						width:40px;
						height:40px;
					}

				}

				&:hover {
					transform:scale(1.1);
				}

			}
		}
	}

}