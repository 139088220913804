@import "_default.scss_include-mixins";

.mobile-menu {
	display:block;
	position:fixed;
	left:0;
	top:-9999px;
	width:100%;
	height:100%;
	background-color:rgba(255,255,255,1);
	z-index:9999;	

	.scroller {
		position:relative;
		width:100vw;
		height:100vh;
		overflow:auto;

		.close {
			position:absolute;
			width:30px;
			height:30px;
			top:14px;
			right:20px;
			color:#fff;
			cursor:pointer;
			padding:5px 10px;
			border-radius:50%;
			background-color:#eee;
			transform:rotate(45deg);

			.label {
				position:relative;
				top:0;
				left:3px;
				width:30px;
			
				.row {
					position:absolute;
					height:2px;
					background-color:#000;
				
					&.row-1 {
						top:9px;
						left:7px;
						width:20px;
						transform:rotate(0deg);
					}
					&.row-2 {
						top:9px;
						left:7px;
						width:20px;
						transform:rotate(90deg);
					}

				}

			}

		}

		.inside {
			padding:40px;
			text-align:center;

			.logo {
				width:100px;
				display:inline-block;
				.st0 {
					fill:#434343;
				}
				.st1 {
					fill:#757575;
				}
			}

			.nav {

				float:left;
				width:100%;
				margin-top:40px;
				text-align:center;

				a {
					font-family:'Cabin',arial;
					font-size:25px;
					line-height:45px;
					text-decoration:none;
					color:#777;

					&.active {
						color:#000;
					}
				}

			}

		}

	}


}